import React from "react";
import Instagram from "../../assets/intagram.png";
import Twiter from "../../assets/twiter.png";
import Telegram from "../../assets/telegram.png";
import WhatsApp from "../../assets/whatsApp.png";
import { Box, Grid, Typography, Link } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../../assets/Link.png";

const SocialIcon = styled("img")({
  width: "40px",
  height: "40px",
  margin: "0 10px",
  cursor: "pointer",
});

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#0A0F2B", padding: "40px 0" }}>
      <Grid 
        container 
        spacing={4} 
        sx={{ maxWidth: "1200px", margin: "0 auto" }} 
        direction={{ xs: "column", md: "row" }}
        alignItems="flex-start"
      >
        <Grid item xs={12} md={4} order={{ xs: 1, md: 1 }}>
          <img src={Logo} alt="HitPoly Logo" style={{ height: '60px', marginBottom: '20px' }} />
          <Typography variant="body1" color="#ffffff" sx={{ lineHeight: "1.8" }}>
            En cada acción que emprendemos, buscamos transformar industrias, cambiar vidas y construir un mundo mejor. Lideramos la Revolución Tecnológica y comunicacional con Propósito y Pasión.
          </Typography>
        </Grid>

        <Grid item xs={12} md={2} order={{ xs: 2, md: 2 }}>
          <Typography variant="h6" color="#1ECDF4" gutterBottom>
            Menu
          </Typography>
          <Link href="/" color="#ffffff" underline="none" display="block" sx={{ marginBottom: "10px" }}>
            Home
          </Link>
          <Link href="/portfolio" color="#ffffff" underline="none" display="block" sx={{ marginBottom: "10px" }}>
            Portfolio
          </Link>
          <Link href="/blog" color="#ffffff" underline="none" display="block" sx={{ marginBottom: "10px" }}>
            Blog
          </Link>
          <Link href="/about" color="#ffffff" underline="none" display="block" sx={{ marginBottom: "10px" }}>
            Nosotros
          </Link>
          <Link href="/contact" color="#ffffff" underline="none" display="block">
            Contacto
          </Link>
        </Grid>

        <Grid item xs={12} md={3} order={{ xs: 3, md: 3 }}>
          <Typography variant="h6" color="#1ECDF4" gutterBottom>
            Politicas
          </Typography>
          <Link href="/privacypolicy" color="#ffffff" underline="none" display="block" sx={{ marginBottom: "10px" }}>
            Politicas de privacidad
          </Link>
          <Link href="/disclaimer" color="#ffffff" underline="none" display="block" sx={{ marginBottom: "10px" }}>
            Descargo de responsabilidad
          </Link>
          <Link href="/termsandconditions" color="#ffffff" underline="none" display="block" sx={{ marginBottom: "10px" }}>
            Terminos y condiciones
          </Link>
          <Link href="/Copyright" color="#ffffff" underline="none" display="block">
            Copyright
          </Link>
        </Grid>

        <Grid item xs={12} md={3} order={{ xs: 4, md: 4 }}>
          <Typography variant="h6" color="#1ECDF4" gutterBottom>
            Contactos
          </Typography>
          <Typography variant="body1" color="#ffffff" sx={{ marginBottom: "10px" }}>
            Av La Molina 1042, Ciudad de Lima, Perú.
          </Typography>
          <Typography variant="body1" color="#ffffff" sx={{ marginBottom: "10px" }}>
            +51933961352
          </Typography>
          <Typography variant="body1" color="#ffffff">
          info@hitpoly.com
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ borderTop: "1px solid #1ECDF4", marginTop: "40px", paddingTop: "20px" }}>
        <Grid container justifyContent="space-between" alignItems="center" direction={{ xs: "column", md: "row" }} sx={{ maxWidth: "1200px", margin: "0 auto" }}>
          <Typography variant="body2" color="#1ECDF4" sx={{ marginBottom: { xs: "20px", md: "0" } }}>
          Copyright © 2024 hitpoly.com | Funciona con hitpoly.com
          </Typography>
          <Box>
          <a href="https://www.instagram.com/hitpoly/" target="_blank" rel="noopener noreferrer">
    <SocialIcon src={Instagram} alt="Instagram" />
</a>

            <SocialIcon src={Twiter} alt="Twiter" />
            <SocialIcon src={Telegram} alt="Telegram" />
            <SocialIcon src={WhatsApp} alt="WhatsApp" />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
