import React from "react";
import Section from "../../assets/Section.png";
import ItemImage from "../../assets/Item.png";
import { Button, Typography, Box, TextField, Paper, useTheme, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import NavGral from "../navbarHomeGral/navbarGral";
import Footer from "../footer/page"

const StyledBox = styled(Box)`
  background-color: #02203E;
  background-image: url(${Section});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 200px;
    padding: 20px 40px 20px 380px;
    text-align: left;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 180px;
    padding: 20px;
    text-align: center;
  }
`;



const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  background-color: #F5F5F5;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    padding: 40px 80px;
    gap: 40px;
  }
`;

const LeftContent = styled(Box)`
  flex: 1;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
    padding-top: 0;
  }
`;

const FormContainer = styled(Paper)`
  flex: 1;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin-top: ${({ theme }) => theme.breakpoints.up('md') ? '-100px' : '-60px'};
  background-color: #FFFFFF;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px;
    margin-top: 0;
  }
`;

const FormHeader = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;

const FormTitle = styled(Typography)`
  font-weight: bold;
  text-align: ${({ theme }) => theme.breakpoints.up('md') ? 'left' : 'center'};
  margin-left: ${({ theme }) => theme.breakpoints.up('md') ? '10px' : '0'};
`;

const SubmitButton = styled(Button)`
  background: linear-gradient(to right, #3C82F4, #9F00FF);
  color: #fff;
  margin-top: 20px;
  padding: ${({ theme }) => theme.breakpoints.up('md') ? '12px 0' : '10px 0'};
  font-weight: bold;
  font-size: ${({ theme }) => theme.breakpoints.up('md') ? '16px' : '14px'};
  text-transform: none;
  border-radius: 20px;
  &:hover {
    background: linear-gradient(to right, #3C82F4, #9F00FF);
    opacity: 0.9;
  }
`;

const Contact = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      <NavGral />
      <StyledBox theme={theme}>
        <Typography variant={isMdUp ? "h5" : "h6"} gutterBottom>
          ¿Quieres agendar una asesoría?
        </Typography>
        <Typography variant={isMdUp ? "h3" : "h4"}>
          Contáctanos
        </Typography>
      </StyledBox>

      <ContentWrapper theme={theme}>
        <LeftContent theme={theme}>
          <Typography variant="h4" gutterBottom>
            ¿Cuál será el próximo paso a seguir?
          </Typography>
          <Typography variant="body1" gutterBottom>
            Estás a un paso más cerca de construir tu producto perfecto.
          </Typography>
          <Box mt={4}>
            <Typography variant="body1" gutterBottom>
              <strong>Dirección:</strong> Av La Molina 1042, Ciudad de Lima, Perú.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Teléfono:</strong> +51 933 961 352
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Email:</strong> info@hitpoly.com
            </Typography>
          </Box>
        </LeftContent>

        <FormContainer elevation={3} theme={theme}>
          <FormHeader theme={theme}>
            <img src={ItemImage} alt="Item" width="50" />
            <FormTitle variant="h6" theme={theme}>
              Escríbenos sobre tu proyecto y prepararemos una propuesta para ti en <strong>24 horas</strong>
            </FormTitle>
          </FormHeader>

          <TextField
            fullWidth
            label="Nombre y Apellido"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Asunto"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            type="email"
          />
          <TextField
            fullWidth
            label="Mensaje"
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
          />
          <SubmitButton fullWidth variant="contained" theme={theme}>
            ENVIAR
          </SubmitButton>
        </FormContainer>
      </ContentWrapper>
      <Footer/>
    </div>
  );
};

export default Contact;
