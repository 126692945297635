
import { Box, Card, Container, Typography, Grid } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import React from "react";
import StayRegister from "../dashboard/componets/stayRegister";
import TraficOrigin from "../dashboard/componets/traficOrigin";
import SiteVisits from "../dashboard/componets/siteVisits";
import PageHeader from "../dashboard/componets/pageHeader";
import ConversionRateCard from "./componets/conversionRateCard";
import SideMenu from "../components/menu/sideMenu";
import VisitorsIndex from "./componets/visitorIndex";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  return (
    <Card  >
      <PageHeader />
      <Box display="flex" sx={{ marginTop:"-3vw", padding: "12px"}}>
      <Box sx={{ padding: 4 }}> 
          <SideMenu />
        </Box>
        
        
        <Box flexGrow={1}>
          <Container maxWidth="xl">

            <Grid container spacing={0} >              
              <Grid item xs={12} md={6} lg={4}>
                <SiteVisits />
                <Box mt={2}>
                  <TraficOrigin />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <StayRegister />
                <Box mt={2}>
                  <ConversionRateCard />
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={4}>
                <VisitorsIndex />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Card>
  );
};

export default Dashboard;
