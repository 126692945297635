import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import NavGral from '../navbarHomeGral/navbarGral';
import MundoImage from '../../assets/mundo.png';
import Cont2 from '../../assets/Cont2.png';
import MundoRosa from '../../assets/mundoRosa.png';
import Section from '../../assets/Section.png';
import Footer from "../footer/page";
import CardSetion from "./components/CardSection";

const rotateAnimation = `
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
      box-shadow: 0 0 20px #00BFFF, 0 0 30px #00BFFF, 0 0 40px #00BFFF, 0 0 50px #00BFFF;
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 0 0 20px #00BFFF, 0 0 30px #00BFFF, 0 0 40px #00BFFF, 0 0 50px #00BFFF;
    }
  }
`;

const StyledBox = styled(Box)`
  background-color: #02203E;
  background-image: url(${Section});
  background-size: contain; /* Ajusta la imagen para que sea completamente visible */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Mantiene la imagen fija durante el desplazamiento */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  text-align: center;

  @media (min-width: 600px) {
    text-align: left;
    background-size: cover; /* Revertir a cover en pantallas más grandes */
  }
`;


const SectionContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  flex-direction: column-reverse;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const SectionContent = styled(Box)`
  color: #fff;
  max-width: 100%;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    max-width: 50%;
    text-align: left;
  }
`;

const SectionImage = styled(Box)`
  position: relative;
  max-width: 100%;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    max-width: 50%;
    margin-bottom: 0;
  }
`;

const StyledImage = styled('img')`
  max-width: 100%;
`;

const RotatingImage = styled(StyledImage)`
  animation: rotate 20s linear infinite;
  ${rotateAnimation}
  filter: drop-shadow(0 0 30px #00BFFF);
  border-radius: 50%;
`;

const FloatingImageContainer = styled(Box)`
  position: absolute;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: -20%;

  @media (min-width: 600px) {
    bottom: -10%;
    display: flex;
  }

  @media (max-width: 599px) {
    display: none; /* Oculta las imágenes en pantallas pequeñas */
  }
`;

const FloatingImage = styled('img')`
  max-width: 100%;
`;

const FloatingText = styled(Typography)`
  color: #fff;
  font-size: 1.2rem;
  margin-top: 10px;
`;

const ButtonStyled = styled(Button)`
  background-color: #C2185B;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  &:hover {
    background-color: #a81c4d;
  }
`;

const HoverEffectImage = styled(StyledImage)`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #00BFFF;
  }
`;

const Home = () => {
  return (
    <div>
      <NavGral />

      {/* Primera Sección */}
      <StyledBox>
        <SectionContainer>
          <SectionContent>
            <Typography variant="h4" color="#BB86FC">UN FUTURO CONECTADO</Typography>
            <Typography variant="h1" sx={{ fontSize: '3rem', my: 1 }}>MEJORA TU NEGOCIO CON HITPOLY</Typography>
            <Typography paragraph>
              Nos esforzamos cada día para seguir revolucionando la industria digital con tecnología y comunicación disruptiva, para impactar positivamente al mundo. Sé parte de un cambio con sentido.
            </Typography>
            <ButtonStyled variant="contained">COTIZA UNA SOLUCIÓN</ButtonStyled>
          </SectionContent>
          <SectionImage>
            <RotatingImage src={MundoImage} alt="Mundo" />
          </SectionImage>
        </SectionContainer>
      </StyledBox>

      {/* Segunda Sección */}
      <StyledBox>
        <SectionContainer>
          <SectionImage>
            <HoverEffectImage src={Cont2} alt="Cont2" />
            <FloatingImageContainer style={{ left: '105%' }}>
              <FloatingImage src={MundoRosa} alt="Mundos Pequeños Rosas" />
              <FloatingText>Innovación</FloatingText>
            </FloatingImageContainer>

            <FloatingImageContainer style={{ left: '150%' }}>
              <FloatingImage src={MundoRosa} alt="Mundos Pequeños Rosas" />
              <FloatingText>Innovación</FloatingText>
            </FloatingImageContainer>

            <FloatingImageContainer style={{ left: '196%' }}>
              <FloatingImage src={MundoRosa} alt="Mundos Pequeños Rosas" />
              <FloatingText>Innovación</FloatingText>
            </FloatingImageContainer>
          </SectionImage>
          <SectionContent>
            <Typography variant="h4" color="#00BFFF">SOBRE NOSOTROS</Typography>
            <Typography variant="h2" sx={{ fontSize: '2.5rem', my: 1 }}>CREEMOS EN LA CAPACIDAD DE CRECER JUNTOS</Typography>
            <Typography paragraph>
              HitPoly es una marca tecnológica líder comprometida con impulsar la innovación transformadora, la comunicación disruptiva y el impacto social positivo. Nos especializamos en ofrecer soluciones tecnológicas avanzadas que empoderan a las empresas y mejoran la vida, manteniendo un fuerte compromiso con la responsabilidad social y la sostenibilidad.
            </Typography>
          </SectionContent>
        </SectionContainer>
      </StyledBox>

      {/* Tercera Sección - Cards */}
      <CardSetion />

      <Footer />
    </div>
  );
}

export default Home;
