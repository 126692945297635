import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './Views/login/page';
import Register from './Views/register/page';
import DashboardPage from './Views/dashboard/page';
import Home from './Views/home/page.jsx';
import Portfolio from './Views/portfolio/page.jsx';
import Blog from './Views/blog/page.jsx';
import About from './Views/about/page.jsx';
import Contact from './Views/contact/page.jsx';
import PrivacyPolicy from "./Views/pageLegals/PrivacyPolicy.jsx";
import Disclaimer from "./Views/pageLegals/disclaimer.jsx";
import Terms from "./Views/pageLegals/termsandconditions.jsx";
import Copyright from "./Views/pageLegals/Copyright.jsx"




function App() {


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />     
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
      <Route path='/disclaimer' element={<Disclaimer/>} />
      <Route path='/termsandconditions' element={<Terms/>} />
      <Route path='/Copyright' element={<Copyright/>} />
    </Routes>
  );
}

export default App;
