import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Box, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/Link.png';

const NavbarButton = styled(Button)({
    background: 'linear-gradient(90deg, #1ECDF4, #8137F2)', 
    borderRadius: '25px', 
    padding: '10px 30px', 
    fontWeight: 'bold',
    color: 'white',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)', 
    textTransform: 'none', 
    fontSize: '16px',
    '&:hover': {
      opacity: 0.9,
    },
});

const NavLink = styled(Typography)({
  color: '#ffffff',
  textDecoration: 'none',
  fontWeight: 'bold',
  fontSize: '12px', 
  '&:hover': {
    color: '#2E5BB1',
  },
});

const NavGral = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navLinks = (
    <List sx={{ width: 250 }} onClick={toggleDrawer(false)}>
      <ListItem button component="a" href="/">
        <ListItemText primary="HOME" />
      </ListItem>
      <ListItem button component="a" href="/portfolio">
        <ListItemText primary="PORTFOLIO" />
      </ListItem>
      <ListItem button component="a" href="/blog">
        <ListItemText primary="BLOG" />
      </ListItem>
      <ListItem button component="a" href="/about">
        <ListItemText primary="ABOUT" />
      </ListItem>
      <ListItem button component="a" href="/contact">
        <ListItemText primary="CONTACT" />
      </ListItem>
    </List>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: '#032340', padding: { xs: '10px 20px', md: '10px 50px' } }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="HitPoly Logo" style={{ height: '60px', marginRight: 'auto' }} />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', gap: '45px' }}>
          <NavLink variant="h6" component="a" href="/">
            HOME
          </NavLink>
          <NavLink variant="h6" component="a" href="/portfolio">
            PORTFOLIO
          </NavLink>
          <NavLink variant="h6" component="a" href="/blog">
            BLOG
          </NavLink>
          <NavLink variant="h6" component="a" href="/about">
            ABOUT
          </NavLink>
          <NavLink variant="h6" component="a" href="/contact">
            CONTACT
          </NavLink>
        </Box>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {navLinks}
        </Drawer>

        <NavbarButton sx={{ display: { xs: 'none', md: 'block' } }}>Consulta Gratis</NavbarButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavGral;
