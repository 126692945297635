
import React from "react";
import NavGral from "../navbarHomeGral/navbarGral";
import { Box, Typography} from '@mui/material';
import Section from "../../assets/Section.png";
import styled from '@emotion/styled';
import Footer from "../footer/page"






const StyledBox = styled(Box)`
background-color: #02203E;
background-image: url(${Section});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
min-height: 100vh;
display: flex;
align-items: center;
justify-content: center;
padding: 20px;
`;
const About = () => {


  return (
    <div>
      <NavGral/>
      <StyledBox>
        <Typography >
          About
        </Typography>
      </StyledBox>
      <Footer/>           
       
      
    </div>
  );
};

export default About;
