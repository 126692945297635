import React from "react";
import NavGral from "../navbarHomeGral/navbarGral";
import { Box, Grid, Typography, Button } from '@mui/material';
import styled from '@emotion/styled';
import Footer from "../footer/page";
import PaulaHenriques from "../../assets/PaulaHenriques.png";
import SistemaModular from "../../assets/SistemasModulares.png";
import Donec from "../../assets/Donec.png";
import BackgroundImage from "../../assets/Section.png";

const Portfolio = () => {

  const StyledBox = styled(Box)`
    background-color: #02203E;
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding: 20px;
    color: #ffffff;
  `;

  const ProjectCard = styled(Box)`
    background-color: #041C37;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: translateY(-10px);
    }
  `;

  return (
    <div>
      <NavGral />
      <StyledBox>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              Nuestros Proyectos
            </Typography>
            <Typography variant="h5" align="center">
              Creando y desarrollando a otro nivel
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <ProjectCard>
              <img src={PaulaHenriques} alt="Paula Henriques" style={{ width: '100%', borderRadius: '10px' }} />
              <Typography variant="h6" mt={2}>
                @PAULHENRIQUESM - ECUADOR
              </Typography>
              <Typography>
                Empowerment femenino en su máxima expresión. Implementamos campañas de Facebook Ads y gestionamos contenido para mujeres que desean recuperar su poder y alcanzar el éxito.
              </Typography>
            </ProjectCard>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <ProjectCard>
              <img src={SistemaModular} alt="Ofisitca" style={{ width: '100%', borderRadius: '10px' }} />
              <Typography variant="h6" mt={2}>
                OFISITCA - VENEZUELA
              </Typography>
              <Typography>
                HePoly es una marca tecnológica líder comprometida con impulsar la innovación transformadora.
              </Typography>
              <Button variant="contained" color="secondary" mt={2}>
                Ver Proyecto
              </Button>
            </ProjectCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <ProjectCard>
              <img src={Donec} alt="Donec Adipi Tristique" style={{ width: '100%', borderRadius: '10px' }} />
              <Typography variant="h6" mt={2}>
                DONEC ADIPI TRISTIQUE
              </Typography>
              <Typography>
                HePoly es una marca tecnológica líder comprometida con impulsar la innovación transformadora.
              </Typography>
              <Button variant="contained" color="secondary" mt={2}>
                Ver Proyecto
              </Button>
            </ProjectCard>
          </Grid>
          
        </Grid>
      </StyledBox>
      <Footer />
    </div>
  );
};

export default Portfolio;
