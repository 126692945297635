import React from 'react';
import { Button, Typography, Box, Grid, Card, CardContent } from '@mui/material';
import styled from '@emotion/styled';
import Software from "../../../assets/softwareMedia.png";
import Diseño from "../../../assets/disenoui.png";
import Marketing from "../../../assets/marketingAbot.png";
import Desarrolo from "../../../assets/desarrolloApp.png";

const StyledBox = styled(Box)`
  background-color: #02203E;
  padding: 40px 20px;
`;

const StyledCard = styled(Card)`
  background-color: #02203E;
  color: #fff;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 10px 2px rgba(0, 191, 255, 0.5); /* Efecto de iluminación celeste */

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px 5px rgba(0, 191, 255, 0.7); /* Aumenta el efecto en hover */
  }
`;

const CardTitle = styled(Typography)`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
`;

const CardContainer = styled(Grid)`
  margin-top: 20px;
`;

const ButtonStyled = styled(Button)`
  background-color: #C2185B;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;

  &:hover {
    background-color: #a81c4d;
  }
`;

const ImageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px; /* Asegúrate de que este valor coincida con el alto de la imagen */
`;

const StyledImage = styled('img')`
  max-height: 100%;
  max-width: 100%;
`;

const CardSection = () => (
  <StyledBox>
    <Typography variant="h4" color="#00BFFF" align="center">NUESTROS SERVICIOS</Typography>
    <Typography variant="h6" color="#fff" align="center" gutterBottom>
      Solucionamos problemas en marcas y empresas de alto impacto internacional.
    </Typography>

    <CardContainer container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={6} md={3}>
        <StyledCard>
          <ImageContainer>
            <StyledImage src={Software} alt="Software a Medida" />
          </ImageContainer>
          <CardContent>
            <CardTitle>Software a Medida</CardTitle>
          </CardContent>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <StyledCard>
          <ImageContainer>
            <StyledImage src={Diseño} alt="Diseño Web UX - IU" />
          </ImageContainer>
          <CardContent>
            <CardTitle>Diseño Web UX - IU</CardTitle>
          </CardContent>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <StyledCard>
          <ImageContainer>
            <StyledImage src={Marketing} alt="Inbound Marketing" />
          </ImageContainer>
          <CardContent>
            <CardTitle>Inbound Marketing</CardTitle>
          </CardContent>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <StyledCard>
          <ImageContainer>
            <StyledImage src={Desarrolo} alt="Desarrollo de Apps" />
          </ImageContainer>
          <CardContent>
            <CardTitle>Desarrollo de Apps</CardTitle>
          </CardContent>
        </StyledCard>
      </Grid>
    </CardContainer>

    <Box display="flex" justifyContent="center">
      <ButtonStyled variant="contained">VER TODOS LOS SERVICIOS</ButtonStyled>
    </Box>
  </StyledBox>
);

export default CardSection;
